import { myVanToast } from "@/plugins/vant.js";
import BigNumber from "bignumber.js";

export const numToString = (num) => {
    let m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
    return num.toFixed(Math.max(0, (m[1] || "").length - m[2]));
};

export const digitalInt = (amount, s = 8) => {
    if (amount) {
        return numToString(new BigNumber(amount).dp(s, BigNumber.ROUND_DOWN));
    } else {
        return `0`;
    }
};

export const copyText = (item, type) => {
    var input = document.createElement("textarea"); // js创建一个input输入框
    input.value = item; // 将需要复制的文本赋值到创建的input输入框中
    document.body.appendChild(input); // 将输入框暂时创建到实例里面
    input.select(); // 选中输入框中的内容
    document.execCommand("Copy"); // 执行复制操作
    document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
    type
        ? null
        : myVanToast({
            status: "success",
            message: "Copied successfully",
        });
};

export const isH5 = () => {
    let sUserAgent = navigator.userAgent.toLowerCase();
    let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    let bIsMidp = sUserAgent.match(/midp/i) == "midp";
    let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    let bIsUcBrowser = sUserAgent.match(/ucbrowser/i) == "ucbrowser";
    let bIsAndroid = sUserAgent.match(/android/i) == "android";
    let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";

    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM || bIsUcBrowser) {
        return true;
    } else {
        return false;
    }
};

export const getRequest = () => {
    var a = decodeURI(location.href).split("&last")[0]; //获取url中"?"符后的字串
    var url = "?" + a.split("?")[1];
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = [];
        strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    return theRequest;
};

export const getCookie = (cname) => {
    var aCookie = document.cookie.split("; ");
    for (var i = 0; i < aCookie.length; i++) {
        var aCrumb = aCookie[i].split("=");
        if (cname === aCrumb[0]) return aCrumb[1];
    }
    return "";
};

export const setCookie = (cname, cvalue) => {
    //cookie过期时间1年
    var Days = 365;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    document.cookie = cname + "=" + cvalue + ";expires=" + exp.toGMTString();
};

export const formatAddress = (address, s = 10, e = 4) => {
    if (address) {
        const prefix = address.substring(0, s);
        const suffix = address.substring(address.length - e);
        return `${prefix}****${suffix}`;
    } else {
        return `...`;
    }
};

export const formatSymbolUpperCaseAll = (itemSymbol) => {
    if (typeof itemSymbol === "string") {
        return itemSymbol.toUpperCase();
    } else if (itemSymbol != null) {
        // 将非字符串且非null/undefined的值转换为字符串再处理
        return String(itemSymbol).toUpperCase();
    } else {
        return "--";
    }
};

export const truncateString = (str) => {
    if (typeof str !== "string") return "";
    return str.length > 15 ? str.slice(0, 15) + "..." : str;
};

export const getChainID = (name) => {
    const status = process.env.VUE_APP_ENV;
    let id = 1;
    if (status === "development") {
        id = name == "ethereum" ? 1 : name == "bsc" ? 97 : name == "base" ? 84532 : 1;
    } else {
        id = name == "ethereum" ? 1 : name == "bsc" ? 56 : name == "base" ? 8453 : 1;
    }
    return id;
}
