const Ethereum = () => import("@/views/ethereum");
const EthereumHome = () => import("@/views/ethereum/home");
const EthereumCreate = () => import("@/views/ethereum/create");
const EthereumDetails = () => import("@/views/ethereum/details");
const Solana = () => import("@/views/Solana");
const SolanaHome = () => import("@/views/Solana/home");
const SolanaCreate = () => import("@/views/Solana/create");
const SolanaDetails = () => import("@/views/Solana/details");
const routes = [
    {
        path: "/",
        redirect: {
            name: "solana",
        },
    },
    {
        path: "/ethereum",
        name: "ethereum",
        component: Ethereum,
        redirect: "/ethereum/home",
        children: [
            {
                path: "/:type/home",
                component: EthereumHome,
            },
            {
                path: "/:type/create",
                component: EthereumCreate,
            },
            {
                path: "/:type/details/:id",
                component: EthereumDetails,
            },
        ],
    },
    {
        path: "/solana",
        name: "solana",
        component: Solana,
        redirect: "/solana/home",
        children: [
            {
                path: "/solana/home",
                component: SolanaHome,
            },
            {
                path: "/solana/create",
                component: SolanaCreate,
            },
            {
                path: "/solana/details/:mint",
                component: SolanaDetails,
            },
        ],
    },
];
export default routes;
