// BOME CHAIN
// 9736 MAINNET
// 9738 TESTNET
export const CHAINS = {
    9736: {
        chainId: 9736,
        name: "Bome Mainnet",
        currency: "BOME",
        network: "Bome Mainnet",
        nativeCurrency: {
            decimals: 18,
            name: "BOME",
            symbol: "BOME",
        },
        rpcUrl: "https://rpc.bomechain.org",
        explorerUrl: "https://bomescan.org",
    },
    9738: {
        chainId: 9738,
        name: "Bome Testnet",
        currency: "BOME",
        network: "Bome Testnet",
        nativeCurrency: {
            decimals: 18,
            name: "BOME",
            symbol: "BOME",
        },
        rpcUrl: "https://testrpc.bomechain.org",
        explorerUrl: "https://testnet.bomescan.org",
    },
    1: {
        chainId: 1,
        name: "Ethereum Mainnet",
        currency: "ETH",
        network: "Ethereum Mainnet",
        nativeCurrency: {
            decimals: 18,
            name: "ETH",
            symbol: "ETH",
        },
        rpcUrl: "https://indulgent-lively-bird.quiknode.pro/5c31d7974c14704e77162ed8f1e29660b6d19270/",
        explorerUrl: "https://etherscan.io",
        tokenFactory: "0x694d15B95413A8b6078909b4cC82959e61f8C83f"
    },
    56: {
        chainId: 56,
        name: "BNB Smart Chain Mainnet",
        currency: "BNB",
        network: "BNB Smart Chain Mainnet",
        nativeCurrency: {
            decimals: 18,
            name: "BNB",
            symbol: "BNB",
        },
        rpcUrl: "https://neat-practical-arrow.bsc.quiknode.pro/b2f485b14431f07a8e9e25951ad16fb364a0dd3a/",
        explorerUrl: "https://bscscan.com",
        tokenFactory: "0x694d15B95413A8b6078909b4cC82959e61f8C83f"
    },
    97: {
        chainId: 97,
        name: "BNB Smart Chain Testnet",
        currency: "tBNB",
        network: "BNB Smart Chain Testnet",
        nativeCurrency: {
            decimals: 18,
            name: "tBNB",
            symbol: "tBNB",
        },
        rpcUrl: "https://data-seed-prebsc-1-s3.bnbchain.org:8545",
        explorerUrl: "https://testnet.bscscan.com",
        tokenFactory: "0x1906e98dB7D6C21b7eFeFe7652c16a6330C0A29d"
    },
    8453: {
        chainId: 8453,
        name: "Base Mainnet",
        currency: "ETH",
        network: "Base Mainnet",
        nativeCurrency: {
            decimals: 18,
            name: "ETH",
            symbol: "ETH",
        },
        rpcUrl: "https://shy-dry-bird.base-mainnet.quiknode.pro/b1b7709a7afb1f86ce2184930060f108d2de2214/",
        explorerUrl: "https://basescan.org",
        tokenFactory: "0x694d15B95413A8b6078909b4cC82959e61f8C83f"
    },
    84532: {
        chainId: 84532,
        name: "Base Sepolia Testnet",
        currency: "ETH",
        network: "Base Sepolia Testnet",
        nativeCurrency: {
            decimals: 18,
            name: "ETH",
            symbol: "ETH",
        },
        rpcUrl: "https://sepolia.base.org",
        explorerUrl: "https://base-sepolia.blockscout.com",
        tokenFactory: "0xfc0d290Acc866563FAF521f3eA68EC30A4C28E34"
    },
};
