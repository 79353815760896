import { createApp } from "vue";
import VueLazyload from "vue-lazyload";
// solana钱包
import SolanaWallets from "solana-wallets-vue";
import { Buffer } from "buffer";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import { PhantomWalletAdapter, SolflareWalletAdapter } from "@solana/wallet-adapter-wallets";

import "./plugins/adaptive";
import "./styles/common.less";
import "./styles/vant.less";
import { myVanToast, myDialog } from "./plugins/vant";
import globalElementsMixins from "./plugins/elements";
import SvgIcon from "@/icons";
import api from "./service/api";
import { getSolanaNetwork } from "@/utils/solana";

// walletconnect配置
import "./contract/wallet/web3ModalConfig";
// web3 提供器
import web3Provider from "./contract/provider";
// import { initContracts } from "./contract/pool/bome";
// initContracts();

const app = createApp(App);
window.Buffer = Buffer;

// phantom为false时不自动连接
const isPhantom = "phantom" in window;
const network = getSolanaNetwork();
const walletOptions = {
    wallets: [new PhantomWalletAdapter(), new SolflareWalletAdapter()],
    autoConnect: !isPhantom ? false : true,
    cluster: network,
};

app.config.globalProperties.$web3 = web3Provider;
// app.config.globalProperties.$initContracts = initContracts();

app.config.globalProperties.$Toast = myVanToast;
app.use(myDialog());
app.mixin(globalElementsMixins);
app.component("svg-icon", SvgIcon);
app.config.globalProperties.$api = api;
app.use(store).use(i18n).use(router).use(VueLazyload).use(SolanaWallets, walletOptions).mount("#app");
