import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "loading_screen"
};
const _hoisted_2 = {
  class: "mask-pet"
};
const _hoisted_3 = {
  version: "1.1",
  id: "loader-1",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  width: "40px",
  height: "40px",
  viewBox: "0 0 50 50",
  style: {
    "enable-background": "new 0 0 50 50"
  },
  "xml:space": "preserve",
  class: "loading"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(), _createElementBlock("svg", _hoisted_3, _cache[0] || (_cache[0] = [_createElementVNode("path", {
    fill: "#ffa80f",
    d: "M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
  }, [_createElementVNode("animateTransform", {
    attributeType: "xml",
    attributeName: "transform",
    type: "rotate",
    from: "0 25 25",
    to: "360 25 25",
    dur: "0.6s",
    repeatCount: "indefinite"
  })], -1)])))])]);
}